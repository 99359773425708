enum SubscriptionLevels {
  GO = 'GO',
  GO2022 = 'GO2022',
  PLUS = 'PLUS',
  MAXI = 'MAXI',
  None = 'none'
}

export const getSubscriptionLevel = (level: string): number => {
  switch (level) {
    case 'GO':
    case 'GO2022':
      return 1;

    case 'PLUS':
      return 2;

    case 'MAXI':
      return 3;

    case 'none':
      return 0;

    default:
      return 0;
  }
};

const hasMinimumSubscriptionLevel = (
  level: SubscriptionLevels,
  minimumLevel: SubscriptionLevels
) => {
  return getSubscriptionLevel(level) >= getSubscriptionLevel(minimumLevel);
};

export default SubscriptionLevels;
export { hasMinimumSubscriptionLevel };
