import 'babel-polyfill';
import 'isomorphic-fetch';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';

import * as moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/fr';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { messages as frMessages } from './locales/fr/messages';
import { messages as nlMessages } from './locales/nl/messages';

i18n.load({
  fr: frMessages,
  nl: nlMessages
});

// Helpers
import SubscriptionStatus from './helpers/subscriptionStatus';
import SubscriptionLevels from './helpers/subscription/levels';

// Services
import { checkProfileProgression } from './services/api/profileService';

const environment = window.location.href.indexOf('syndy.be') >= 0 ? 'production' : 'staging';

Sentry.init({
  dsn: 'https://b5674f88d391489282fc606ca612413b@sentry.io/1883778',
  environment
});

(async () => {
  const appRoot = document.getElementById('app-root');

  const userName = appRoot && appRoot.attributes['data-user-name'];

  if (userName) {
    checkProfileProgression(userName.value);
  }

  const language = appRoot && appRoot.attributes['data-language'];
  const levelAttribute = appRoot && appRoot.attributes['data-subscription-level'];

  moment.locale(language.value.split('-')[0]);
  i18n.activate(language.value.split('-')[0]);

  let subscriptionStatus = SubscriptionStatus.Unknown;
  if (!levelAttribute) {
    subscriptionStatus = SubscriptionStatus.NotSubscribed;
  } else {
    const isSubscribed =
      levelAttribute.value === SubscriptionLevels.MAXI ||
      levelAttribute.value === SubscriptionLevels.PLUS ||
      levelAttribute.value === SubscriptionLevels.GO ||
      levelAttribute.value === SubscriptionLevels.GO2022;

    subscriptionStatus = isSubscribed
      ? SubscriptionStatus.Subscribed
      : SubscriptionStatus.NotSubscribed;
  }

  // Inject Views
  if (document.getElementById('app-root')) {
    const { default: App } = await import('./app');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>,
      document.getElementById('app-root')
    );
  }
  if (document.getElementById('company-profile-root')) {
    const { default: CompanyProfile } = await import('./components/companyProfile');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <CompanyProfile subscriptionStatus={subscriptionStatus} />
      </I18nProvider>,
      document.getElementById('company-profile-root')
    );
  }
  if (document.getElementById('employee-profile-root')) {
    const { default: EmployeeProfileEditor } = await import('./components/employeeProfile');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <EmployeeProfileEditor />
      </I18nProvider>,
      document.getElementById('employee-profile-root')
    );
  }
  if (document.getElementById('employee-cv-root')) {
    const { default: EmployeeDetails } = await import('./components/employeeDetails');

    const userId = document.getElementById('employee-cv-root').attributes['data-userId'].value;

    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <EmployeeDetails employeeUserId={userId} />
      </I18nProvider>,
      document.getElementById('employee-cv-root')
    );
  }
  if (document.getElementById('joboffer-details-root')) {
    const { default: JobOfferDetails } = await import('./components/jobOfferDetails');

    const root = document.getElementById('joboffer-details-root');
    const jobOfferId = root.attributes['data-jobOfferId'].value;

    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <JobOfferDetails jobOfferId={jobOfferId} />
      </I18nProvider>,
      root
    );
  }
  if (document.getElementById('template-profile-root')) {
    const { default: TemplateProfile } = await import('./components/templateProfile');

    const subscriptionLevel =
      document.getElementById('app-root').attributes['data-subscription-level'].value;

    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <TemplateProfile subscriptionLevel={subscriptionLevel} />
      </I18nProvider>,
      document.getElementById('template-profile-root')
    );
  }
  if (document.getElementById('login-root')) {
    const { default: Login } = await import('./components/login');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <Login />
      </I18nProvider>,
      document.getElementById('login-root')
    );
  }
  if (document.getElementById('job-offers-list')) {
    const { default: JobOffers } = await import('./components/jobOffers');
    const root = document.getElementById('job-offers-list');

    setTimeout(() => {
      const empty = root.attributes['data-empty'];
      const text = empty ? empty.value : null;

      ReactDOM.render(
        <I18nProvider i18n={i18n}>
          <JobOffers text={text} />
        </I18nProvider>,
        root
      );
    }, 750);
  }
  if (document.getElementById('job-offers-public')) {
    const { default: JobOffersPublic } = await import('./components/jobOffersPublic');

    const root = document.getElementById('job-offers-public');

    setTimeout(() => {
      const empty = root.attributes['data-empty'];
      const companyId = parseInt(root.attributes['data-company-id'].value);
      const text = empty ? empty.value : null;

      ReactDOM.render(
        <I18nProvider i18n={i18n}>
          <JobOffersPublic text={text} companyId={companyId} />
        </I18nProvider>,
        root
      );
    }, 750);
  }
  if (document.getElementsByClassName('relations-list').length) {
    const { default: Relations } = await import('./components/relations');

    const roots = document.getElementsByClassName('relations-list');

    setTimeout(() => {
      for (let i = 0; i < roots.length; i++) {
        const empty = roots[i].attributes['data-empty'];
        const maskAttribute = roots[i].attributes['data-mask'];
        const mask = maskAttribute ? eval(maskAttribute.value) : false;
        const page = roots[i].attributes['data-page'].value;

        // Subscription level required to see this card
        const minimumSubscriptionLevelAttribute =
          roots[i].attributes['data-minimumSubscriptionLevel'];
        const minimumSubscriptionLevel = minimumSubscriptionLevelAttribute
          ? minimumSubscriptionLevelAttribute.value
          : SubscriptionLevels.GO2022;

        // show filter component on this list
        const showFilterAttribute = roots[i].attributes['show-filter'];
        const showFilter = showFilterAttribute ? eval(showFilterAttribute.value) : false;

        const status = roots[i].attributes['data-status'].value;
        const take = roots[i].attributes['data-take'].value;
        const text = empty ? empty.value : null;

        ReactDOM.render(
          <I18nProvider i18n={i18n}>
            <Relations
              mask={mask}
              page={page}
              showFilter={showFilter}
              status={status}
              take={take}
              text={text}
              minimumSubscriptionLevel={minimumSubscriptionLevel}
            />
          </I18nProvider>,
          roots[i]
        );

        setTimeout(() => {
          const animations = document.getElementsByClassName('animated');
          Array.from(animations).forEach(function (el) {
            el.classList.remove('animated');
          });
        }, 1000);
      }
    }, 750);
  }
  if (document.getElementById('company-search-root')) {
    const { default: Search } = await import('./components/search');

    const subscriptionLevel =
      document.getElementById('app-root').attributes['data-subscription-level'].value;
    const subscriptionPlan =
      document.getElementById('app-root').attributes['data-subscription-plan'].value;

    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <Search subscriptionLevel={subscriptionLevel} subscriptionPlan={subscriptionPlan} />
      </I18nProvider>,
      document.getElementById('company-search-root')
    );
  }
  if (document.getElementById('employee-search-root')) {
    const { default: EmployerDirectory } = await import('./components/employerDirectory');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <EmployerDirectory />
      </I18nProvider>,
      document.getElementById('employee-search-root')
    );
  }
  if (document.getElementById('subscription-root')) {
    const { default: Subscription } = await import('./components/subscription');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <Subscription />
      </I18nProvider>,
      document.getElementById('subscription-root')
    );
  }
  if (document.getElementById('notifications-root')) {
    const { default: Notifications } = await import('./components/notifications');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <Notifications subscriptionStatus={subscriptionStatus} />
      </I18nProvider>,
      document.getElementById('notifications-root')
    );
  }
  if (document.getElementById('questions-modal-root')) {
    const { default: QuestionsModal } = await import('./components/questionsModal');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <QuestionsModal />
      </I18nProvider>,
      document.getElementById('questions-modal-root')
    );
  }
  if (document.getElementById('partners-modal-root')) {
    const { default: PartnerModal } = await import('./components/partners/confirm-modal');
    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <PartnerModal />
      </I18nProvider>,
      document.getElementById('partners-modal-root')
    );
  }
  if (document.getElementById('network-menu-item')) {
    const { default: NetworkBadge } = await import('./components/networkBadge');

    const div = document.createElement('div');
    document.getElementById('network-menu-item').appendChild(div);

    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <NetworkBadge />
      </I18nProvider>,
      div
    );
  }
})();
