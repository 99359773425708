export async function checkProfileProgression(userName) {
  if (localStorage.getItem('userName') === null || localStorage.getItem('userName') !== userName) {
    localStorage.removeItem('userComplete');
  }

  if (
    localStorage.getItem('userComplete') === null ||
    localStorage.getItem('userComplete') === 'undefined'
  ) {
    let response = await fetch('/api/profile/is-complete/me', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (response.ok) {
      let data = await response.json();

      localStorage.setItem('userName', data.userName);
      localStorage.setItem('userComplete', data.userComplete);
    } else {
      // Show in console error message
      let error = await response.text();
      console.log(error);
    }
  }
}
